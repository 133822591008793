.contact-us {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #edeff4;

  .form-iframe {
    height: 1000px;
    width: 100%;
    border: none;
  }
}
