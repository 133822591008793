.home {
    width: auto;
}

@media only screen and (max-width: 1200px) {
    .home {
        max-width: 100%;
    }

    .hero-text-1 {
        font-size: 48px;
    }
}