.partners {
    padding: 2.2rem 0;
}

.heading-partners {
    font-size: 26px;
    text-transform: uppercase;
    letter-spacing: 0.75px;
    font-weight: 600;
    color: #888;
}
.container {
    text-align: center;
}
.logo-partners {
    justify-content: space-evenly;
    display: flex; 
    align-items: center;
    filter: grayscale(100%);
    opacity: 80%;
}

.logo-partners img {
    height: 48px;
}