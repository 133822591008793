.cont-service-all {
  padding-top: 2.5rem;
  justify-content: space-around;
}

.section-services {
  background-color: #f1f4f6;
  padding-top: 1.5rem;
}

.sub-ser {
  color: #3fc2f5;
}

.cont-service {
  justify-content: space-around;

  display: flex;
  padding-top: 2rem;
}

.cont-alt {
  padding-top: 5rem;
}

.service-img {
  height: 30rem;
  width: 28rem;
}

.service-header-text {
  text-align: center;
}

.cont-text-services {
  box-shadow: #7cc2de;
}

.header-ser {
  font-size: 1.8rem;
  font-weight: 800;
}

.pass-ser {
  padding-top: 20px;
  font-size: 1.2rem;
  color: #070707;
}

.ser-header {
  font-size: 2.1rem;
}

.ser-header:last-child {
  color: #3fc2f5;
}

/* Media Queries */

@media only screen and (max-width: 992px) {
}

@media only screen and (max-width: 768px) {
  .cont-service {
    display: grid;
  }

  .cont-text-services {
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .cont-service {
    display: grid;
  }

  .service-img {
    height: 30rem;
    width: 24rem;
  }
}

@media only screen and (max-width: 360px) {
  .service-img {
    height: 28rem;
    width: 22rem;
  }
}
