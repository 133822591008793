.section {
  width: 90vw;
  margin: 5rem auto;
  max-width: 1170px;
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}

.section-center {
  margin: 0 auto;
  margin-top: 4rem;
  width: 80vw;
  max-width: 800px;
  text-align: center;
  position: relative;
  display: flex;
  overflow: hidden;
}

.article {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.3s linear;
}

.person-img {
  border-radius: 50%;
  margin-bottom: 1rem;
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.person-img-container,
.slider-subtitle,
.position {
  text-align: center;
}

.slider-subtitle {
  color: #5b5757;
  font-weight: 600;
}

article h4 {
  text-transform: uppercase;
  color: hs1(21, 62%, 45%);
  margin-bottom: 0.25rem;
}

.title {
  text-transform: capitalize;

  color: hs1(209, 34%, 30%);
}

.title {
  text-align: center;
}
.span-title {
  color: #3fc2f5;
}

.position {
  color: #3fc2f5;
  font-size: 12px;
  font-weight: 600;
}

.title h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.text {
  max-width: 35em;
  margin: 0 auto;
  margin-top: 2rem;
  line-height: 2;
  color: hs1(210, 22% 49%);
}

@media (min-width: 800px) {
  .text {
    max-width: 45em;
  }
}
