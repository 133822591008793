.wedo-cont-first {
  text-align: center;
  font-size: 2rem;
  font-weight: 800;
}

.section-wedo {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #f0ffff;
}

.wedo-image-cont {
  padding-top: 1.5rem;
}

.about-img {
  height: 14 rem;
  width: 23rem;
  margin: auto;
}

.span-wedo {
  color: #3fc2f5;
}
.wedo-text-cont {
  text-align: center;
  padding-top: 1.1rem;
}

.wedo-text-head {
  font-size: 2.5rem;
  font-weight: 600;
  margin-top: 1.1rem;
  margin-bottom: 1.1rem;
}

.wedo-text-sub-head {
  font-size: 1.8rem;
}

.wedo-text-sub-pass {
  font-size: 1.2rem;
  display: inline-block;
  overflow-wrap: break-word;
  inline-size: 80rem;
}

.span-company {
  color: #3fc2f5;
}

/* Media Queries */

@media only screen and (max-width: 1200px) {
  .wedo-text-sub-pass {
    inline-size: 60rem;
  }
}

@media only screen and (max-width: 992px) {
  .wedo-text-sub-pass {
    inline-size: 47rem;
  }
}

@media only screen and (max-width: 768px) {
  .wedo-text-sub-pass {
    inline-size: 35rem;
  }
}

@media only screen and (max-width: 600px) {
  .wedo-text-sub-pass {
    font-size: 19px;
    overflow-wrap: break-word;
    inline-size: 23rem !important;
  }
}

@media only screen and (max-width: 360px) {
  .wedo-text-sub-pass {
    font-size: 16px;
    inline-size: 18rem;
  }
}
