.section__padding {
    padding: 4rem 4rem;
}

.footer {
    background-color: #fff;
}

.footer-logo {
    width: 100%;
}

.sb__footer {
    display: flex;
    flex-direction: column;
}

.sb__footer-links{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    margin-bottom: 2rem;
}

.sb__footer-links_div {
    width: 150px;
    margin: 1rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    color: #000;
}

a {
    color: rgb(0, 0, 0.2);
    text-decoration: none;
}

.socialmedia {
    display: flex;
    flex-direction: row;
}

.socialmedia img {
    width: 80%;
}

.sb__footer-links_dev h4 {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0.9rem;
}

.sb__footer-links_dev p {
    font-size: 12px;
    line-height: 15px;
    margin: 0.5rem 0;
    cursor: pointer;
}

.sb__footer-below {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.2rem;
}

.sb__footer-below-links {
    display: flex;
    flex-direction: row;
}

.sb__footer-below-links p {
    font-size: 13px;
    line-height: 15px;
    margin-left: 2rem;
    color: black;
    font-weight: 600;
}

hr {
    color: #121111 !important;
    width: 100%
}

.sb__footer-copyright p {
    font-size: 13px;
    line-height: 15px;
    color: rgb(25, 24, 24);
    font-weight: 600;
}

@media screen and (max-width: 850px) {
    .sb__footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px) {
    .sb__footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .sb__footer-links div {
        margin: 1rem 0;
    }

    .sb__footer-btn {
        font-size: 14px;
        line-height: 20px;
    }

    .sb__footer-below {
        flex-direction: column;
        justify-content: left;
    }

    .sb__footer-below-links {
        flex-direction: column;
    }

    .sb__footer-below-links p {
        margin-left: 0rem;
        margin-top: 1rem;
    }
}

@media screen and (max-width: 400px) {
    .sb__footer-heading h1 {
        font-size: 27px;
        line-height: 30px;
    }
}
