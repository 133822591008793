.header-all {
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 0 4.8rem;
}

.logo-b {
  height: 4.2rem;
}

.navbar-list {
  list-style: none;
  display: flex;
  gap: 3.2rem;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  font-size: 1.2rem;
  transition: all 0.3s;
}

.navbar-list:hover {
  color: #3fc2f5;
}

.bg {
  position: relative;
  background-image: linear-gradient(
      rgba(15, 14, 14, 0.7),
      rgba(24, 23, 23, 0.7)
    ),
    url(../Header/backg.jpg);

  min-height: 100vh;
  background-position: center;
  background-size: cover;
}

.contact-link {
  cursor: pointer;
}

.btn-cont {
  text-align: center;
}

.hero-cont {
  padding-top: 12rem;
  text-align: center;
}

.hero-text-1 {
  text-transform: uppercase;
  font-size: 28px;
  font-weight: 700;
  color: #3fc2f5;
}

.hero-text-2 {
  color: #fff;
  text-transform: uppercase;
  font-size: 38px;
  letter-spacing: 1px;
  padding-top: 07px;
}

.hero-pass {
  color: #fff;
  padding-top: 10px;
  font-size: 20px;
}

.btn-cont {
  cursor: pointer;
  margin-top: 20px;
  padding: 15px 28px;
  color: #fff;
  background-color: #3fc2f5;
  border-radius: 20px;
}

.nav-btn {
  display: none;
}

/* MEDIA QUERIES */

@media only screen and (max-width: 1200px) {
  .navbar-list {
    font-size: 1.2rem;
  }

  .hero-text-1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 992px) {
  .btn-cont {
    margin-top: 200px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 600px) {
  .navbar-list {
    display: none;
  }

  .header-all {
    padding-right: 2.2rem;
    padding-left: 1rem;
  }

  .btn-cont {
    margin-top: 100px;
    border-radius: 1px solid #3fc2f5;
  }

  .nav-btn {
    display: block;
    font-size: 25px;
    padding: 2px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-nav {
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100vh;
    width: 100%;
    background: #ffffff;
    z-index: 4793934;
  }

  .mobile-nav__header {
    padding: 0 18px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mobile-nav__nav-list {
    list-style: none;
    padding: 0 0 0 18px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .mobile-nav__nav-route {
    text-decoration: none;
    font-size: 24px;
    margin: 0;
  }

  .nav-icon {
    color: #3fc2f5;
  }

  .hero-pass {
    font-size: 17px;
  }

  .hero-text-1 {
    font-size: 30px;
  }

  .hero-text-2 {
    font-size: 28px;
  }

  .btn-cont {
    margin-top: 90px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 360px) {
  .hero-cont {
    padding-top: 7rem;
  }

  .btn-cont {
    margin-top: 50px;
    margin-bottom: 30px;
  }
}
